<template lang="pug">
div
  b-container#search-container(fluid)
    b-alert.mt-2.w-50.m-auto(
      show,
      variant='danger',
      v-if='!permissions && loaded'
    ) You have not been granted access to any systems, please contact system admin.
    div(v-if='permissions')
      b-overlay(:show='loadingTable || !loaded')
        b-row.m-2
          b-col.m-2
            b-card#search-box(v-if='loaded', fluid)
              b-form(@submit.prevent='onSubmit')
                b-row.m-2.justify-content-md-center.flex-wrap-reverse
                  b-col.m-2(sm='auto')
                    b-row.m-2(sm='auto')
                      p.mb-0 
                        b From:
                      b-input-group
                        b-form-input.w-auto(
                          v-model='dateFrom',
                          type='text',
                          placeholder='YYYY-MM-DD',
                          autocomplete='off',
                          size='sm'
                        )
                        b-form-datepicker(
                          v-model='dateFrom',
                          size='sm',
                          button-only
                        )
                    b-row.m-2
                      p.mb-0 
                        b To:
                      b-input-group
                        b-form-input.w-auto(
                          v-model='dateTo',
                          type='text',
                          placeholder='YYYY-MM-DD',
                          autocomplete='off',
                          size='sm'
                        )
                        b-form-datepicker(
                          v-model='dateTo',
                          size='sm',
                          button-only
                        )
                    b-row.m-2
                      b-button.mt-3(
                        @click='resetDates',
                        variant='danger',
                        size='sm'
                      ) Reset
                    b-row.m-2
                      b-button(
                        :disabled='!searchCheck',
                        variant='success',
                        type='submit',
                        size='sm'
                      ) Hae

                  b-col.m-2(sm='auto')
                    #classCB
                      b-alert(
                        show,
                        v-if='classes.length == 0 && loaded',
                        variant='danger'
                      ) No reviewed labels
                      span(v-if='classes.length > 0')
                        b Label
                      b-form-checkbox-group.mt-2(
                        :options='classes',
                        v-model='classSelected',
                        :disabled='highlightChecked',
                        stacked
                      )

                  b-col.m-2(sm='auto')
                    #sizeCB
                      b-alert(
                        show,
                        v-if='sizeOptions.length == 0 && loaded',
                        variant='danger'
                      ) No reviewed sizes
                      span(v-if='sizeOptions.length > 0')
                        b Size
                      b-form-checkbox-group.mt-xl-2(
                        :options='sizeOptions',
                        v-model='sizeSelected',
                        :disabled='highlightChecked',
                        stacked
                      )
                  b-col.m-2(sm='auto')
                    #pipeCB
                      span(v-if='pipes.length > 0')
                        b Pipe
                      b-form-checkbox-group.mt-2(
                        :options='pipes',
                        v-model='pipeSelected',
                        stacked
                      )
                  b-col.m-2(sm='auto')
                    #classCB
                      span(v-if='classes.length > 0')
                        b Options
                      b-form-checkbox-group.mt-2(
                        :options='optionFields',
                        disabled-field='notEnabled',
                        v-model='optionsSelected',
                        stacked
                      )
      b-row.m-2
        b-col.m-2
          b-progress(
            v-if='loadingZip',
            :value='(zipCount / 100) * zipMax',
            :max='zipMax',
            show-progress
          )
      b-row.m-2
        b-col.m-2
          b-alert(v-if='noResult && loaded == true', show, variant='danger') No results.
          b-alert(
            v-if='tableData.length > 500 && loaded == true',
            show,
            variant='warning'
          ) Generating zip may take a while.
          //b-alert(v-if="tableData.length > 3000" show variant="danger") Over 3000 results, zipping disabled. Please refine your search to download zip.
          b-card(v-if='tableData.length > 0 || totalQuery')
            b-overlay(:show='loadingZip')
              p(v-if='totalImages') Result: {{ totalImages }}
              //p(v-if="totalQuery") Result: {{totalQuery}}

              b-button.ml-1.mb-1(v-if='thumbnails', v-b-toggle.collapse2) Images
              b-button.ml-1.mb-1(v-if='tableData', v-b-toggle.collapse1) Table
              b-button.ml-1.mb-1(
                v-if='tableData && isAdmin',
                @click='callExcel("table")'
              ) Excel
                b-icon.ml-1(icon='cloud-download')
              b-button.ml-1.mb-1(
                v-if='tableData && isAdmin',
                @click='generateZip()'
              ) Zip
                b-icon.ml-1(icon='cloud-download')
              b-button.ml-1.mb-1(
                v-if='tableData && fetchMore',
                @click='getMore',
                variant='info'
              ) Get More
              b-collapse#collapse1.mt-4(v-model='collapseTable')
                b-table#table(
                  striped,
                  hover,
                  sticky-header,
                  :items='tableData',
                  :fields='fields',
                  small
                )
              b-collapse#collapse2.mt-4(v-model='collapseImg')
                ThumbnailView(:Data='thumbnails', @imgSelected='getImage')
                b-modal(
                  ok-only,
                  :title='selectedID.date',
                  v-model='imageSelected',
                  v-if='selectedID',
                  size='xl'
                )
                  b-card
                    b-button(
                      v-if='isAdmin',
                      @click='edit = !edit',
                      :variant='edit ? "warning" : "info"'
                    ) Edit
                    b-table#table(
                      striped,
                      hover,
                      sticky-header,
                      :items='infoTable',
                      small,
                      stacked
                    )

                    boundBox(
                      v-if='selectedID.image && isAdmin && edit',
                      :Source='selectedID',
                      @updateInfo='updateInfoTable'
                    )

                    b-img.img-fluid.mt-4(
                      v-if='!edit',
                      :src='`data:image/png;base64,${selectedID.image}`',
                      draggable='false'
                    )
</template>
<script>
//import Chart from "../components/Chart";
import {
  getDateString,
  getTimeString,
  getFullDateString,
} from '../utils/getDate';
import { GET_ZIP } from '@/store/constants';
import { GET_QUERY, GET_REPORT_PERMS, IMAGE_GET } from '../store/constants';
import toExcel from '../utils/toexcel';
import ThumbnailView from '../components/thumbnailview.vue';
import boundBox from '../components/edit-box';

export default {
  name: 'Report',
  components: {
    ThumbnailView,
    boundBox,
  },
  data() {
    return {
      collapseImg: false,
      collapseTable: false,
      edit: false,
      totalImages: null,
      reviewedImages: null,
      notReviewedImages: null,
      images: [],
      classes: [],
      sizeOptions: [],
      pipes: [],
      years: null,
      classSelected: [],
      sizeSelected: [],
      pipeSelected: [],
      optionsSelected: [],
      tableData: [],
      thumbnails: [],
      dateFrom: null,
      dateTo: null,
      loaded: false,
      noResult: false,
      loadingZip: false,
      loadingTable: false,
      imageSelected: false,
      selectedID: null,
      permissions: false,
      zipCount: 0,
      zipMax: 0,
      batch: 0,
      totalQuery: null,
      fetchMore: false,
      infoTable: [],
      fields: [
        { key: 'filename', sortable: true },
        { key: 'date', sortable: true },
        { key: 'time', sortable: true },
        { key: 'label', sortable: true },
        { key: 'size', sortable: true },
        { key: 'pipe', sortable: true },
      ],
      optionFields: [
        { text: 'Skipped', value: 'Skipped', notEnabled: false },
        { text: 'Highlighted', value: 'Highlighted', notEnabled: false },
      ],
    };
  },
  watch: {
    optionsSelected: function () {
      if (this.optionsSelected.includes('Skipped')) {
        this.optionFields.forEach((option) => {
          if (option.text == 'Highlighted') {
            option.notEnabled = true;
          }
        });
      } else if (this.optionsSelected.includes('Highlighted')) {
        this.optionFields.forEach((option) => {
          if (option.text == 'Skipped') {
            option.notEnabled = true;
          }
        });
      } else {
        this.optionFields.forEach((option) => {
          option.notEnabled = false;
        });
      }
    },
  },
  computed: {
    isAdmin() {
      return ['admin', 'developer', 'moderator'].includes(
        this.$store.getters.getUser.status
      );
    },

    highlightChecked() {
      return this.optionsSelected.includes('Highlighted');
    },
    searchCheck() {
      if (
        this.optionsSelected.length > 0 ||
        this.pipeSelected.length > 0 ||
        this.classSelected.length > 0 ||
        this.sizeSelected.length > 0 ||
        this.dateFrom ||
        this.dateTo
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getLabelsByUserPerms();
  },
  methods: {
    updateInfoTable(newValue) {
      this.getLabelsByUserPerms();
      this.infoTable = [];
      newValue.new.result.forEach((item) => {
        this.infoTable.push({
          label: item.label,
          size: item.size,
        });
      });
    },
    getMore() {
      this.batch += 1;
      this.fetchReport();
    },
    updateTable() {
      this.onSubmit();
      this.collapseImg = true;
    },
    getImage(id) {
      this.$store.dispatch(IMAGE_GET, id).then((result) => {
        result[0].date = getFullDateString(result[0].utc_date);
        this.infoTable = [];
        this.selectedID = result[0];
        this.selectedID.result.forEach((item) => {
          this.infoTable.push({
            label: item.label,
            size: item.size,
          });
        });

        this.imageSelected = true;
      });
    },
    resetDates() {
      this.dateFrom = null;
      this.dateTo = null;
      this.tableData = [];
      this.classSelected = [];
      this.sizeSelected = [];
      this.thumbnails = [];
      this.noResult = false;
    },
    generateZip() {
      this.zipCount = 0;
      this.loadingZip = true;
      this.$store.dispatch(GET_ZIP, this.images).then((result) => {
        this.zipMax = result.length;
        var JSZip = require('jszip');
        var FileSaver = require('file-saver');
        const yourDate = new Date();
        var date = yourDate.toISOString().split('T')[0];
        var name = this.classSelected.join();
        var zip = new JSZip();
        result.forEach((element) => {
          zip.file(element.filename, element.image, { base64: true });
        });

        zip
          .generateAsync({ type: 'blob' }, (metadata) => {
            this.zipCount = metadata.percent;
          })
          .then(function (content) {
            FileSaver.saveAs(content, `${name}(${result.length})-${date}.zip`);
          })
          .then(() => {
            this.loadingZip = false;
          });
      });
    },

    callExcel(id) {
      const yourDate = new Date();
      var date = yourDate.toISOString().split('T')[0];
      var name = this.classSelected.join();
      toExcel(id, `${name}-${date}`);
    },
    fetchReport() {
      this.loadingTable = true;
      this.noResult = false;
      this.tableData = [];
      this.images = [];
      this.thumbnails = [];
      const {
        optionsSelected,
        pipeSelected,
        classSelected,
        sizeSelected,
        dateFrom,
        dateTo,
        batch,
      } = this;

      this.$store
        .dispatch(GET_QUERY, {
          optionsSelected,
          pipeSelected,
          classSelected,
          sizeSelected,
          dateFrom,
          dateTo,
          batch,
        })

        .then((result) => {
          if (!result || result.length == 0) {
            this.noResult = true;
          } else {
            this.tableData = this.buildTable(result);
            this.totalImages = result.length;
            this.fetchMore = result.length === 1000 ? true : false;
            result.forEach((image) => {
              this.images.push(image.imageID);
              this.thumbnails.push(image);
            });
          }
        })
        .then(() => {
          this.loadingTable = false;
        });
    },
    onSubmit() {
      this.batch = 0;
      this.fetchReport();
    },

    buildTable(result) {
      var table = [];
      result.forEach((element) => {
        table.push({
          filename: element.filename,
          date: getDateString(element.date),
          time: getTimeString(element.date),
          label: element.label,
          size: element.size,
          pipe: element.pipe,
        });
      });

      return table;
    },
    getLabelsByUserPerms() {
      this.$store.dispatch(GET_REPORT_PERMS).then((result) => {
        if (result.pipe.length > 0) {
          this.sizeOptions = result.size;
          this.classes = result.label;
          this.pipes = result.pipe;
          this.permissions = true;
        } else {
          this.permissions = false;
        }
      });
      setTimeout(() => {
        this.loaded = true;
      }, 1000);
    },
  },
};
</script>
<style scoped lang="stylus">
#image-modal {
  max-width: 100% !important;
}

.modal-dialog {
  max-width: 100% !important;
}

.modal-dialog-md {
  max-width: 100% !important;
}

#classCB, #sizeCB, #pipeCB {
  text-align: left;
  margin-top: 0em;
}

.img-thumbnail {
  position: relative;
  height: 100px;
  width: 120px;
  padding: 0px;
  overflow: scroll;
}

.b-table-sticky-header {
  max-height: 500px;
}

.thumbnail-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
}

.img-thumbnail:hover {
  position: relative;
  transform: scale(1.5);
  transition: 0.1s;
  z-index: 1;
}
</style>
